<template>
  <div class="sb-service-section">
    <div :class="{ 'inner-container fad': !isMd }">
      <div v-if="selectedServices.length > 1" class="sb-service-section-tabs">
        <a
          v-for="(service, i) in selectedServices"
          :key="i"
          class="sb-service-section-tabs-item"
          :class="{ active: currentIdx === i }"
          :style="{
            '--activeServiceSectionBgColor': service.main_color || colorsPrimary,
            '--activeServiceSectionColor': service.accent_color || colorsPhite,
          }"
          @click="currentIdx = i"
        >
          <ImageLazy
            v-if="service.logo && currentIdx !== i"
            class-variant="sb-small-icon"
            :src="service.logo.filename"
          />
          <ImageLazy
            v-else-if="service.icon_active && currentIdx === i"
            class-variant="sb-small-icon"
            :src="service.icon_active.filename"
          />
          <ImageLazy
            v-else-if="service.logo && currentIdx === i"
            class-variant="sb-small-icon"
            :src="service.logo.filename"
          />
          {{ service.title }}
        </a>
      </div>
      <template v-for="(service, i) in selectedServices" :key="service.uuid">
        <div
          v-if="currentIdx === i"
          class="sb-service-section-container"
          :data-test-id="service?.title?.toLowerCase()?.replace(/\s/g, '-') + '-container'"
        >
          <div
            class="sb-service-section-container-wrapper"
            :class="{ rtl: service.rtl }"
            :data-test-id="service?.title?.toLowerCase()?.replace(/\s/g, '-') + '-wrapper'"
          >
            <div v-if="service.main_img" class="sb-service-section-container-wrapper-image">
              <div
                class="sb-service-section-container-wrapper-image-img"
                :data-test-id="service?.title?.toLowerCase()?.replace(/\s/g, '-') + '-img'"
              >
                <ImageLazy :src="service.main_img.filename" />
              </div>
            </div>

            <div class="sb-service-section-container-wrapper-txt">
              <div
                class="sb-service-section-container-wrapper-txt-tagline"
                :data-test-id="service?.title?.toLowerCase()?.replace(/\s/g, '-') + '-tagline'"
              >
                <span>{{ service.tagline }}</span>
              </div>
              <div
                class="sb-service-section-container-wrapper-txt-title"
                :data-test-id="service?.title?.toLowerCase()?.replace(/\s/g, '-') + '-title'"
              >
                <ImageLazy v-if="service.icon" :src="service.icon.filename" class-variant="sb-icon" />
                <span>{{ service.title }}</span>
              </div>

              <div
                class="sb-service-section-container-wrapper-txt-text"
                :data-test-id="service?.title?.toLowerCase()?.replace(/\s/g, '-') + '-text'"
              >
                <span v-html="richText(service.description)" />
              </div>

              <div
                v-if="service.buttons && service.buttons.length"
                class="sb-service-section-container-wrapper-txt-buttons u-d-f u-fw-wrap-md"
              >
                <SbCtaGroup :buttons="service.buttons" class-variant="full-width" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Mobile from '@/mixins/mobile-mixin'

import SbCtaGroup from '@/components/Storyblok/SbCtaGroup'

export default {
  name: 'SbServicesSection',
  components: {
    SbCtaGroup,
  },
  mixins: [Mobile],
  props: {
    services: { type: Array, default: () => [] },
  },
  data() {
    return {
      currentIdx: 0,
    }
  },
  computed: {
    ...mapGetters('proArea', ['getStoryByUid', 'getTestimonyByUid']),
    colorPrimary() {
      return window?.getComputedStyle(document?.body)?.getPropertyValue(`--primary`)
    },
    colorWhite() {
      return window?.getComputedStyle(document?.body)?.getPropertyValue(`--white`)
    },
    selectedServices() {
      return this.services
        .map(x => {
          const story = this.getStoryByUid(x)
          if (story) {
            return story?.content || story
          } else return null
        })
        .filter(x => x)
    },
  },
  methods: {
    richText(text) {
      if (text) {
        return renderRichText(text)
      }
      return null
    },
  },
}
</script>

<style lang="scss">
.sb-service-section {
  padding: $spacing-xl;
  background-color: var(--white);

  @include mq($mq-xs) {
    padding: $spacing-md;
  }

  &-tabs {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-md;
    justify-content: center;
    margin-bottom: $spacing-lg;
    width: 100%;
    @include mq($mq-md) {
      gap: $spacing-xs;
    }
    &-item {
      align-items: center;
      border-radius: $spacing-xs;
      cursor: pointer;
      display: flex;
      gap: $spacing-sm;
      padding: $spacing-xs $spacing-sm;
      font-weight: 400;
      font-size: pxToRem(15px);
      &.active {
        background-color: var(--activeServiceSectionBgColor);
        color: var(--activeServiceSectionColor);
      }
      &:hover:not(&.active) {
        background-color: var(--bg-grey);
      }
      @include mq($mq-md) {
        flex: 1 1 40%;
        gap: $spacing-xs;
        padding: $spacing-xs $spacing-xs;
      }
    }
  }

  &-container {
    &-wrapper {
      display: flex;
      margin: 0 auto;
      gap: $spacing-md;
      align-items: center;

      @include mq($mq-sm) {
        flex-direction: column !important;
      }

      &.rtl {
        flex-direction: row-reverse;
      }

      &-image {
        flex: 1;
        &-img {
          width: 490px;
          height: 400px;
          border-radius: 10px;
          overflow: hidden;
          align-items: center;
          display: flex;

          @include mq($mq-md) {
            width: 322px;
          }

          @include mq($mq-sm) {
            width: 100%;
          }
          img {
            width: 100%;
          }
        }
      }

      &-txt {
        flex: 1;
        max-width: 50%;
        @include mq($mq-md) {
          max-width: 60%;
        }
        @include mq($mq-sm) {
          max-width: 100%;
        }
        &-tagline {
          font-weight: 800;
          font-size: pxToRem(25px);
          color: var(--dark-blue);
          padding-bottom: $spacing-sm;
        }

        &-title {
          display: flex;
          align-items: center;
          gap: $spacing-sm;
          font-size: pxToRem(15px);
          color: var(--dark-blue);
          padding-bottom: $spacing-sm;
        }

        &-links {
          padding-bottom: $spacing-md;
          .sb-buttons-link-hover-img {
            width: 28px;
            height: 28px;
          }
        }

        &-text {
          font-weight: 400;
          font-size: pxToRem(15px);
          color: var(--dark-blue);
          padding-bottom: $spacing-md;
        }

        &-buttons {
          justify-content: space-around;
          padding-bottom: $spacing-md;

          .sb-cta-groups-wrapper {
            @include mq($mq-xs) {
              padding: 0;
            }
          }

          .sb-cta-groups {
            justify-content: flex-start;
          }

          &-button {
            position: relative;
            flex-grow: 1;

            &-icon {
              position: absolute;
              right: 10px;
              width: 28px;
              height: 28px;

              @include mq($mq-md) {
                display: none;
              }

              @include mq($mq-sm) {
                display: block;
              }
            }
          }
        }

        &-testimony {
          padding: $spacing-md;
          border-radius: 10px;
          background-color: var(--bg-grey);
          gap: $spacing-md;

          &-left {
            &-img {
              width: 80px;
              height: 80px;
              border-radius: 90px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          &-txt {
            &-name {
              font-weight: 800;
              font-size: pxToRem(15px);
              padding-bottom: $spacing-sm;

              &-role {
                color: var(--green);
              }
            }

            &-text {
              font-weight: 500;
              font-size: pxToRem(12px);
            }
          }
        }
      }
    }
    &-companies {
      align-items: center;
      display: flex;
      flex: 1;
      padding-top: $spacing-md;
    }
  }
  .grayscale {
    filter: grayscale(100%);
    transition: filter 0.3s ease-in-out;
    &:hover {
      filter: grayscale(0);
    }
  }
}
</style>
